import { sorting } from "@/lib/bigcommerce/constants";
import {
  BundleThreeCardImage,
  BundleThreeCardImageMobile,
  BundleTwoCardImage,
  BundleTwoCardImageMobile,
  BundleOneCardImage,
  BundleOneCardImageMobile,
  BundleOneMainImage,
  BundleOneMainImageMobile,
  BundleThreeMainImage,
  BundleThreeMainImageMobile,
  BundleTwoMainImage,
  BundleTwoMainImageMobile,
} from "../Theme";

const UPDATE_CART_SUCCESSFULLY = "Cart updated successfully";
const VERSION_2 = "v2";
const SHOP_THE_LOOK_PAGE = "landing_page_ecommerce";
const ZERO = 0;
const ICON = "icon";
const PAGE_TITLE = "Wear That - ";
const BUTTON = "button";
const SUBMIT_TYPE = "submit";
const BUTTON_TYPE = "button";
const MINUS_ONE = "-1";
const SURVEY_ENDPOINT = `${process?.env?.NEW_WEB_URL}survey-register`;
const DATE_TEXT = "date";
const DEFAULT = "default";
const NEWSLETTER_ENDPOINT = "communication/subscribe-newsletter/";
const SERENA = "serena";
const INPUT_FIELD_CLASS =
  "bg-transparent border border-FIELD_PINK focus:outline-none rounded w-[100%] h-[41px] px-4 ";
const NEW_INPUT_FIELD_CLASS =
  "bg-transparent border border-New_Primary_Default focus:outline-none w-[100%] h-[32px] px-3 ";
const INPUT_FIELD_ERROR =
  "bg-transparent border border-Error_Default focus:outline-none rounded w-[100%] h-[41px] px-4 ";
const TAKE_STYLE_QUIZ = "Take The Style Quiz";
const SEARCH_TEXT = "Search";
const SUBSCRIBE_TEXT = "Subscribe to our Newsletter";
const EAGER = "eager";
const LAZY = "lazy";
const MOBILE_SCREEN_BREAKPOINT = "1025";
const CATEGORY_TEXT = "CATEGORY";
const NEW_COLLECTION_BUTTON_ID_TEXT =
  "landing-page-new-collection-section-button";
const HOT_LIST_BUTTON_ID_TEXT = "landing-page-hot-list-section-button";
export {
  UPDATE_CART_SUCCESSFULLY,
  NEW_COLLECTION_BUTTON_ID_TEXT,
  HOT_LIST_BUTTON_ID_TEXT,
  SERENA,
  MOBILE_SCREEN_BREAKPOINT,
  TAKE_STYLE_QUIZ,
  DEFAULT,
  SUBMIT_TYPE,
  BUTTON_TYPE,
  VERSION_2,
  SURVEY_ENDPOINT,
  SHOP_THE_LOOK_PAGE,
  ZERO,
  ICON,
  BUTTON,
  PAGE_TITLE,
  MINUS_ONE,
  DATE_TEXT,
  INPUT_FIELD_CLASS,
  NEW_INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
  NEWSLETTER_ENDPOINT,
  SEARCH_TEXT,
  SUBSCRIBE_TEXT,
  EAGER,
  LAZY,
  CATEGORY_TEXT,
};

export const BRAND_COLLECTION_IDS = ["100", "101", "106", "108", "124", "135"];

export const COLLECTION_VALUES = {
  basma: "Basma Collection",
  serena: "Serena Collection",
  serenaRamadan: "Serena Ramadan Collection",
  tamara: "Tamara Collection",
  jessie: "Jessie Collection",
  serena: "Serena Collection",
  "old-money-edit": "Old Money Edit",
  "corporate-edit": "Corporate Edit",
  "creative-edit": "Creative Edit",
  "education-edit": "Education Edit",
  "entrepreneurial-edit": "Entrepreneurial Edit",
  "event-pr-management-edit": "Event/PR Edit",
  "all-work-edit": "All Edits",
};
export const CATEGORY_IDS = {
  basma: 100,
  serenaByTheSea: 135,
  serenaRamadan: 101,
  serenaCollection: 108,
  tamara: 106,
  jessie: 124,
  serena: 101,
  "old-money-edit": 148,
  "corporate-edit": 165,
  "creative-edit": 166,
  "education-edit": 167,
  "entrepreneurial-edit": 168,
  "event-pr-management-edit": 169,
  "all-work-edit": 170,
};

export const SHOP_FILTERS = [
  {
    key: "sort",
    name: "SORT BY",
    options: sorting,
  },
  //
  // {
  //   key: "color",
  //   name: "COLOUR",
  //   options: [
  //     { title: "BLACK", slug: "black", colorCode: "#D6869D" },
  //     { title: "PINK", slug: "pink", colorCode: "#FFC2C2" },
  //     { title: "WHITE", slug: "white", colorCode: "#FFFFFF" },
  //     { title: "GREY", slug: "grey", colorCode: "#3A3A3A" },
  //     { title: "GREEN", slug: "green", colorCode: "#3F9C6D" },
  //   ],
  // },
  // {
  //   key: "product",
  //   name: "PRODUCT TYPE",
  //   options: [
  //     { title: "LONG DRESS", slug: "longDress" },
  //     { title: "SHORT DRESS", slug: "shortDress" },
  //   ],
  // },
  {
    key: "price",
    name: "PRICE",
    options: [
      { title: "0 - 250 AED", slug: "[0,250]" },
      { title: "250 - 500 AED", slug: "[250,500]" },
      { title: "500 - 750 AED", slug: "[500,750]" },
      { title: "750 - 1000 AED", slug: "[750,1000]" },
      { title: "1000+ AED", slug: "[1000,100000]" },
    ],
  },
  {
    key: "collection",
    name: "COLLECTION",
    options: [
      { title: "Basma Collection", slug: "100" },
      { title: "Serena Ramadan Collection", slug: "101" },
      { title: "Jessie Collection", slug: "124" },
      { title: "Tamara Collection", slug: "106" },
      { title: "Serena Collection", slug: "108" },
    ],
  },
];

export const BUNDLES_DATA = [
  {
    key: 162,
    bundlePrice: "1593",
    bundleTitle: "Essential Starter Capsule",
    bundleName: "Essential Starter Capsule",
    bundleLink: "/bundles/essential-starter-capsule/",
    savingsPercentage: 10,
    bundleImageDesktop: BundleOneCardImage,
    bundleImageMobile: BundleOneCardImageMobile,
    coverImageDesktop: BundleOneMainImage,
    coverImageMobile: BundleOneMainImageMobile,
  },
  {
    key: 163,
    bundlePrice: "2440",
    bundleTitle: "Elevated Work Capsule",
    bundleName: "Elevated Work Capsule",
    bundleLink: "/bundles/elevated-work-capsule/",
    savingsPercentage: 15,
    bundleImageDesktop: BundleTwoCardImage,
    bundleImageMobile: BundleTwoCardImageMobile,
    coverImageDesktop: BundleTwoMainImage,
    coverImageMobile: BundleTwoMainImageMobile,
  },
  {
    key: 164,
    bundlePrice: "2854",
    bundleTitle: "Ultimate Professional Capsule",
    bundleName: "Ultimate Professional Capsule",
    bundleLink: "/bundles/ultimate-professional-capsule/",
    savingsPercentage: 20,
    bundleImageDesktop: BundleThreeCardImage,
    bundleImageMobile: BundleThreeCardImageMobile,
    coverImageDesktop: BundleThreeMainImage,
    coverImageMobile: BundleThreeMainImageMobile,
  },
];

export const ECOM_TERMS_AND_CONDITIONS_LINK =
  "https://mkt-production.s3.me-south-1.amazonaws.com/terms+%26+conditions/Wearthat+Ecomm+T%26C.pdf";
