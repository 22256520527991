"use client";
import React from "react";
import { PROCESSING_GIF_ALT } from "./constants";
import { PRIMARY_BUTTON_CLASS, PRIMARY_BUTTON_DISABLED_CLASS } from "./styles";
import { LoadingWhiteSpinner } from "../../../../public/Theme";
import Image from "next/image";
import Link from "next/link";
import { SURVEY_ENDPOINT } from "../../../../public/Constants/EnumConstants";
import LoadingDots from "../../LoadingDots";

const NewPrimaryButton = ({
  text,
  onClick,
  processing,
  width,
  href,
  prefetch,
  className,
  style,
  type,
  disabled,
  section,
  buttonId,
  image,
  target,
  icon,
}) => {
  const buttonClass = disabled
    ? PRIMARY_BUTTON_DISABLED_CLASS
    : PRIMARY_BUTTON_CLASS;
  const ButtonText = () => (
    <div className="flex items-center gap-x-3">
      {text}
      {icon && <Image src={icon} alt="button icon" width={24} height={24} />}
    </div>
  );
  return href ? (
    <Link
      prefetch={prefetch}
      target={target && target}
      href={`${href}`}
      id={buttonId}
      data-cy={buttonId}
      passHref
      className={width + PRIMARY_BUTTON_CLASS + className}
      style={style}
      onClick={() => {
        if (href?.includes(SURVEY_ENDPOINT) && section) {
          gtag("event", "survey_start_click", {
            section: section,
            button_text: text,
            content_id: "StartSurveyClick",
          });
        }
        if (onClick) {
          onClick();
        }
      }}
      name={text}
    >
      {processing ? <LoadingDots className=" bg-white" /> : <ButtonText />}
      {image && (
        <Image src={image} width={14} height={14} alt={PROCESSING_GIF_ALT} />
      )}
    </Link>
  ) : (
    <button
      type={type}
      className={width + buttonClass + className}
      style={style}
      onClick={onClick}
      disabled={disabled}
      id={buttonId}
      data-cy={buttonId}
    >
      {processing ? <LoadingDots className=" bg-white" /> : <ButtonText />}
      {image && (
        <Image src={image} width={20} height={14} alt={PROCESSING_GIF_ALT} />
      )}
    </button>
  );
};

export default NewPrimaryButton;
