"use client";
import React from "react";
import TypographyMontserrat from "@/components/Atoms/TypographyMontserrat";
import ShoppingProductCard from "@/components/Molecules/ShoppingProductCard";
import NewPrimaryButton from "@/components/Atoms/Buttons/NewPrimaryButton";
import { useMediaQuery } from "@/components/CustomHooks/UseMediaQuery";
import { sendGTMEvent } from "@next/third-parties/google";

const LandingPageProductsSection = ({
  buttonLink,
  buttonText,
  buttonId,
  products,
  sectionHeading,
  subHeading,
  numberOfProductsToShowOnMobile,
}) => {
  const breakpoint = useMediaQuery("600");
  return (
    <div className="flex flex-col items-center">
      <TypographyMontserrat
        align={"center"}
        customStyle={" !text-xl sm:!text-[32px] uppercase "}
        fontWeight="bold"
        text={sectionHeading}
      />
      {subHeading && (
        <TypographyMontserrat
          align={"center"}
          customStyle={" mt-3 sm:mt-4 !text-base !sm:text-xl "}
          fontWeight="normal"
          text={subHeading}
        />
      )}
      <div className="mt-6 sm:mt-8 flex flex-col gap-y-8 sm:gap-y-5">
        <div className="grid grid-rows-auto  grid-cols-2 sm:grid-cols-4 gap-x-4 sm:gap-x-6 gap-y-5 sm:gap-y-10">
          {products?.map((product, index) => {
            let productsNumbersToShow = breakpoint
              ? numberOfProductsToShowOnMobile || 4
              : 8;
            return (
              index < productsNumbersToShow && (
                <ShoppingProductCard
                  key={index + String(product?.id)}
                  link={`${product?.path || product?.handle}`}
                  name={product?.name || product?.title}
                  images={product?.images?.edges || product?.images}
                  price={
                    product?.prices?.price?.value ||
                    product?.priceRange?.maxVariantPrice?.amount
                  }
                  currencyCode={
                    product?.priceRange?.maxVariantPrice?.currencyCode
                  }
                  brandName={product?.brand}
                  categories={product?.categories}
                  onClick={() =>
                    sendGTMEvent({
                      event: "LandingPageInteraction",
                      interaction_type: "click",
                      interaction_item: `LandingPageProductsSection:${product?.name}`,
                    })
                  }
                />
              )
            );
          })}
        </div>
        {buttonText && (
          <NewPrimaryButton
            href={buttonLink}
            text={buttonText}
            buttonId={buttonId}
          />
        )}
      </div>
    </div>
  );
};

export default LandingPageProductsSection;
