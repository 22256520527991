import clsx from "clsx";

const dots = " mx-[3px] inline-block h-1 w-1 animate-pulse rounded-md";

const LoadingDots = ({ className }) => {
  return (
    <span className="mx-2 inline-flex items-center justify-center">
      <span className={clsx(dots, className)} />
      <span className={clsx(dots, "animation-delay-300", className)} />
      <span className={clsx(dots, "animation-delay-600", className)} />
    </span>
  );
};

export default LoadingDots;
