"use client";
import TypographyMontserrat from "@/components/Atoms/TypographyMontserrat";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { TERTIARY_BUTTON_TEXT } from "./constant";
import { sendGTMEvent } from "@next/third-parties/google";

const EditsCard = ({ title, image, link }) => {
  return (
    <Link
      className="relative block"
      href={link}
      onClick={() =>
        sendGTMEvent({
          event: "EditsCardHomePage",
          interaction_type: "click",
          interaction_item: title,
        })
      }
    >
      <TypographyMontserrat
        align={"center"}
        customStyle={" !text-lg xl:!text-2xl uppercase mb-2 md:mb-4 "}
        fontWeight="bold"
        text={title}
      />

      <div className="relative w-full h-auto">
        <Image
          alt={title}
          src={image}
          width={0}
          height={0}
          sizes="(max-width:768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          className="w-full h-auto"
        />
        <TypographyMontserrat
          align={"center"}
          customStyle={
            "!text-xl uppercase absolute bottom-0 left-0 right-0 bg-Outlines bg-opacity-70  py-2"
          }
          decoration={"underline"}
          fontWeight="bold"
          text={TERTIARY_BUTTON_TEXT}
        />
      </div>
    </Link>
  );
};

export default EditsCard;
