"use client";
import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { VideoIcon } from "../../../public/Theme";
import {
  CONTROLS_CONTAINER,
  PLAY_BUTTON_CONTAINER,
  VIDEO_OVERLAY,
} from "./styles";
import Image from "next/image";

const HlsJsPlayer = ({
  autoPlay = true,
  source,
  containerClassName,
  videoClassName,
  videoPlaceHolder,
  preload = "auto",
  muted = true,
}) => {
  const videoRef = useRef();
  const [showButtons, setShowButtons] = useState({
    play: autoPlay ? true : false,
  });
  useEffect(() => {
    if (Hls?.isSupported()) {
      var hls = new Hls();
      hls?.loadSource(source);
      hls?.attachMedia(videoRef?.current);
    } else if (
      videoRef?.current?.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoRef.current.src = source;
    }
  }, []);
  return (
    <div className={containerClassName}>
      <video
        ref={videoRef}
        autoPlay={autoPlay}
        className={videoClassName}
        muted={muted}
        loop
        playsInline
        preload={preload}
        poster={videoPlaceHolder}
      >
        <source type="application/x-mpegURL"></source>
      </video>

      <div
        className={CONTROLS_CONTAINER}
        onClick={() => {
          if (showButtons?.play) {
            videoRef?.current?.pause();
            videoRef?.current?.classList?.add(VIDEO_OVERLAY);
            setShowButtons({ ...showButtons, play: false });
          } else {
            videoRef?.current?.play();
            videoRef?.current?.classList?.remove(VIDEO_OVERLAY);
            setShowButtons({ ...showButtons, play: true });
          }
        }}
      >
        <div className={PLAY_BUTTON_CONTAINER}>
          {!showButtons?.play && (
            <Image
              src={VideoIcon}
              alt="play button icon"
              width={40}
              height={40}
            />
          )}
        </div>

        <div />
      </div>
    </div>
  );
};

export default HlsJsPlayer;
